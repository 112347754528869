<template>
  <v-container class="fidelity-page">
    <CategoryTitle
      :category="category"
      :showOnMobile="true"
      :add-container="false"
    />
    <div v-if="fidelityCards">
      <div
        v-for="fidelity in fidelityCards"
        :key="fidelity.fidelityCardId"
        class="d-flex justify-space-between align-center mb-2 grey lighten-1 pa-3 rounded-md"
      >
        <div>
          <div class="secondary--text text-uppercase font-weight-600">
            {{ fidelity.store.name }}
          </div>
          <div class="text-secondary font-weight-600">{{ fidelity.code }}</div>
        </div>
        <v-btn
          icon
          class="primary--text ml-3"
          @click.stop.prevent="deleteCard(fidelity.code)"
          aria-label="Elimina"
        >
          <v-icon>$delete</v-icon>
        </v-btn>
      </div>
    </div>
    <ResponseMessage class="mt-3" :response="response" />
    <div v-if="user">
      <p>
        {{
          $t("fidelity.addCard", {
            firstName: user.firstName,
            lastName: user.lastName,
            date: $dayjs(user.person.birthDate).format("DD MMMM YYYY")
          })
        }}
      </p>
      <v-form ref="form" class="d-flex" @submit.prevent="addCard">
        <v-row>
          <v-col cols="12" sm="6">
            <label v-html="`${$t('profile.fidelityCard')} *`" />
            <v-text-field
              :placeholder="$t('profile.CartaFedeltàPlaceholder')"
              hide-details="auto"
              v-model="cardCode"
              :rules="[requiredRules()]"
              dense
              outlined
              required
            ></v-text-field>
            <div class="py-3">* {{ $t("profile.requiredFields") }}</div>
          </v-col>
          <v-col cols="12" class="d-flex justify-end">
            <v-btn depressed type="submit" large color="primary">
              {{ $t("fidelity.addCardBtn") }}
            </v-btn>
          </v-col>
          <v-col
            ><div class="d-flex img-wraper">
              <img
                alt="CardExample"
                width="350"
                src="/img_layout/cardExample.png"
              /></div
          ></v-col>
        </v-row>
      </v-form>
    </div>
  </v-container>
</template>
<style lang="scss">
.fidelity-page {
  label {
    font-weight: 600;
    text-transform: uppercase;
  }
  .img-wraper {
    img {
      max-width: 100%;
      border-radius: 8px;
    }
  }
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import categoryMixins from "~/mixins/category";
import { requiredValue } from "~/validator/validationRules";
import { mapActions } from "vuex";

import UserService from "~/service/userService";
import CustomService from "@/service/customService";

import ResponseMessage from "@/components/ResponseMessage.vue";

export default {
  name: "Fidelity",
  components: {
    CategoryTitle,
    ResponseMessage
  },
  mixins: [categoryMixins],
  data() {
    return {
      requiredRules: requiredValue,
      user: null,
      fidelityCards: null,
      cardCode: null,
      response: {}
    };
  },
  computed: {},
  methods: {
    ...mapActions({
      getCart: "cart/getCart"
    }),
    async getUserData() {
      const result = await UserService.getUserDetail();
      if (result) {
        this.user = result;
      }
    },
    async getCards() {
      const result = await CustomService.getCards();
      if (result) {
        this.fidelityCards = result.fidelityCards;
      }
    },
    async addCard() {
      if (this.$refs.form.validate()) {
        CustomService.addCard(this.cardCode, this.user.person.birthDate).then(
          async data => {
            if (data.response.status == 0) {
              this.fidelityCards = data.data.fidelityCards;
              this.response = {};
              await this.getCart();
            } else {
              this.response = data.response;
            }
          }
        );
      }
    },
    async deleteCard(code) {
      CustomService.removeCard(code).then(async data => {
        if (data.response.status == 0) {
          this.fidelityCards = data.data.fidelityCards;
          this.response = {};
          await this.getCart();
        } else {
          this.response = data.response;
        }
      });
    }
  },
  async mounted() {
    await this.getUserData();
    await this.getCards();
  }
};
</script>
